import { Types } from 'mongoose';

export type StringObjectId = unknown & Types.ObjectId;

export enum GENDERS {
  MALE = 'Male',
  FEMALE = 'Female',
  UNKNOWN = 'Unknown',
}

export enum MARTIAL_STATUS {
  SINGLE = 'Single',
  MARRIED = 'Married',
  DIVORCED = 'Divorced',
  WIDOW = 'Widow',
}

export enum FAMILY_STATUS {
  LIVING = 'Living',
  DECEASED = 'Deceased',
  UNKNOWN = 'Unknown',
}

export enum PAYMENT_METHOD {
  CASH = 'Cash',
  CHEQUE = 'Cheque',
  DEBIT_CREDIT_CARD = 'DEBIT/ CREDIT CARD',
  PAYMENT_LINK = 'PAYMENT_LINK',
  STANDING_ORDER = 'STANDING_ORDER',
}

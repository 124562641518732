// Always use plural values
// API_KEYS: api_keys <--- CORRECT
// API_KEYS: api_key <--- INCORRECT

export enum DB_COLLECTION_NAMES {
  // A
  ARTICLES = 'articles',
  ARTICLE_CATEGORIES = 'article_categories',
  ARTICLE_SUB_CATEGORIES = 'article_sub_categories',
  ARTICLE_TAGS = 'article_tags',
  ACCESS_CREDENTIALS = 'access_credentials',
  API_KEYS = 'api_keys',
  AUTH_REQUESTS = 'auth_requests',
  AUTH_CODES = 'auth_codes',
  // C
  CONNECTED_APPS = 'connected_apps',
  CONNECTIONS = 'connections',
  // D
  DELETED_CLIENT_SECRETS = 'deleted_client_secrets',
  DELETED_API_KEYS = 'deleted_api_keys',
  // E
  ERROR_LOGS = 'error_logs',
  EXTRA_DOCUMENT = 'extra_document',
  // I
  IDENTITIES = 'identities',
  INTEGRATION_ENDPOINTS = 'integration_endpoints',
  INTEGRATION_MAPPINGS = 'integration_mappings',
  INTEGRATION_FILE_CONFIGS = 'integration_file_configs',
  INTEGRATION_FILE_DATA = 'integration_file_data',
  INTEGRATION_FILE_UPLOADS = 'integration_file_uploads',
  // L
  LOGS = 'logs',
  // M
  MESSAGES = 'messages',
  MESSAGES_RESPONSES = 'messages_responses',
  MODULES = 'modules',
  // N
  NOTIFICATIONS = 'notifications',
  NOTIFICATION_IDENTITIES = 'notification_identities',
  NOTICES = 'notices',
  // O
  OTP = 'otps',
  // P
  PERMISSIONS = 'permissions',
  PRIVILEGES = 'privileges',
  POLICIES = 'policies',
  POLICY_PROPOSALS = 'policy_proposals',
  POLICY_PROPOSALS_DOCUMENTS = 'policy_proposals_docs',
  POST_IT_NOTES = 'post-it-notes',
  PERSONALIZATIONS = 'personalizations',
  PREDEFINED_REFERENCES_COUNTRIES = 'predefined_reference_countries',
  PREDEFINED_REFERENCES_STATES = 'predefined_reference_states',
  PREDEFINED_REFERENCES_DISTRICTS = 'predefined_reference_districts',
  PREDEFINED_REFERENCES_CITIES = 'predefined_reference_cities',
  PUSH_NOTIFICATION_DEVICE = 'push_notification_devices',
  PAYMENTS = 'payments',
  // Q
  QUICK_ACTIONS = 'quick_actions',
  // R
  REFERENCE_CATEGORIES = 'reference_categories',
  REFERENCES = 'references',
  REFRESH_TOKENS = 'refresh_tokens',
  ROLES = 'roles',
  REMINDER = 'reminders',
  REFERRERS = 'referrers',
  // S
  SETUP_BACKUP_SCHEDULES = 'setup_backup_schedules',
  SETUP_CLIENT = 'setup_clients',
  SETUP_COLOR_PALLETS = 'setup_color_palettes',
  SETUP_GENERAL = 'setup_generals',
  SETUP_IAM = 'setup_iams',
  SETUP_LANGUAGES = 'setup_languages',
  SETUP_CURRENCIES = 'setup_currencies',
  SETUP_MESSAGE_GROUPS = 'setup_message_groups',
  SETUP_MESSAGE_RULES = 'setup_message_rules',
  SETUP_NOTIFICATION_CONFIGURATIONS = 'setup_notification_configs',
  SETUP_MESSAGE_CONFIG = 'setup_message_config',
  SETUP_NOTIFICATION_CONFIG = 'setup_notification_configs',
  SETUP_PWA = 'setup_progressive_web_apps',
  SETUP_REFERENCE_MAPPINGS = 'setup_reference_mappings',
  SETUP_RELEASES = 'setup_releases',
  SETUP_EXTERNAL_SYSTEMS = 'setup_external_systems',
  SETUP_SECURITY = 'setup_securities',
  SETUP_SECURITY_WEB_HOOKS = 'setup_security_web_hooks',
  SETUP_STORAGE = 'setup_storages',
  SETUP_SUPPORT = 'setup_supports',
  SETUP_SYSTEM_VARIABLES = 'setup_system_variables',
  SETUP_MESSAGE = 'setup_messages',

  STORAGE = 'storages',
  STORAGE_ENTITY = 'storage_entities',
  STORAGE_ENTITY_INTERNAL_SHARE = 'storage_entities_internal_shares',
  STORAGE_ENTITY_EXTERNAL_SHARE = 'storage_entities_external_shares',
  // T
  TEMPLATES = 'templates',
  TASK = 'tasks',
  // U
  USERS = 'users',
  // V
  VERIFICATION_TOKENS = 'verification_tokens',
  // W
  WIDGETS = 'widgets',
  WIDGET_LAYOUT = 'widget_layouts',

  // AVO specific
  // A
  ACTIVITIES = 'activities',
  ACHIEVEMENTS = 'ACHIEVEMENTS',
  ACHIEVEMENTS_CONFIGURATIONS = 'ACHIEVEMENTS_CONFIG',
  ACHIEVEMENTS_LOCK_DATE_CONFIGURATIONS = 'achievements_lock_date_configurations',

  // C
  COMPETITIONS = 'COMPETITIONS',

  // L
  LEADS = 'leads',
  LEAD_COMMENTS = 'lead_comments',
  LEAD_NEED_ANALYSIS = 'lead_need_analyses',
  LEADS_REASSIGN_HISTORY = 'leads_reassign_history',

  // R
  RECRUITMENTS = 'recruitments',
  RECRUITMENTS_PROCESSES = 'recruitment_processes',

  //P
  PAYOUTS_CONFIGURATIONS_BATCH_SCHEDULES = 'payouts_configurations_batch_schedules',
  PAYOUTS_CONFIGURATIONS_EARNINGS = 'payouts_configurations_earnings',
  PAYOUTS_CONFIGURATIONS_DEDUCTIONS = 'payouts_configurations_deductions',
  PAYOUTS_CONFIGURATIONS_BANK_ORDER_FIELDS = 'payouts_configurations_bank_order_fields',
  PAYOUTS_CONFIGURATIONS_ENTRIES = 'payouts_configurations_entries',
  PAYOUTS_CONFIGURATIONS_DATA_MAPPING = 'payouts_configurations_data_mapping',
  PAYOUTS_TRANSACTIONS = 'payouts_transactions',
  PROMOTIONS = 'promotions',

  // T
  TARGETS = 'targets',
  TARGET_CONFIGURATIONS = 'target_configurations',

  //ACCOUNTING RELATED
  CHART_OF_ACCOUNTS = 'CHART_OF_ACCOUNTS',
  ENTRIES = 'ENTRIES',
}
